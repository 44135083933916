import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "button"];

  connect() {
    this.originalFont = "OSMellySans-regular";
    this.newFont = "mirtha-dermisache-demo";
    this.isActive = false;
  }

  toggleFont() {
    this.isActive = !this.isActive;

    this.contentTargets.forEach((element) => {
      element.style.fontFamily = this.isActive ? this.newFont : this.originalFont;
    });

    this.buttonTarget.textContent = this.isActive ? "Restaurar Tipografía" : "Aplicar Mirtha Dermisache";
  }
}
