import DeviceDetector from "device-detector-js";

window.deviceDetector = new DeviceDetector();
window.device = window.deviceDetector.parse(navigator.userAgent);

import * as Turbo from "@hotwired/turbo";
Turbo.start();

import { Application } from "@hotwired/stimulus";
window.Stimulus = Application.start();

import PlayerController from "./controllers/player_controller";
import StreamingController from "./controllers/streaming_controller";
import CameraController from "./controllers/camera_controller";
import ImagePlayerController from "./controllers/image_player_controller";
import PlayerPlaylistController from "./controllers/player_playlist_controller";
import FontController from "./controllers/font_controller";

Stimulus.debug = window.env.JEKYLL_ENV !== "production";
Stimulus.register("player", PlayerController);
Stimulus.register("streaming", StreamingController);
Stimulus.register("camera", CameraController);
Stimulus.register("image-player", ImagePlayerController);
Stimulus.register("player-playlist", PlayerPlaylistController);
Stimulus.register("font", FontController);

document.addEventListener("turbo:load", (event) => {
  document
    .querySelectorAll("a[href^='http://'],a[href^='https://'],a[href^='//']")
    .forEach((a) => {
      a.rel = "noopener";
      a.target = "_blank";
    });
});
